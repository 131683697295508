<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  School Invoices
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Pending School Invoices
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field type="date" label="View from date" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field class="ml-2" type="date" label="View up to date" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select v-model="invoice.state"
                            :items="state"
                            item-text="text"
                            dense
                            outlined
                            item-value="value"
                            label="State"
                            required
                            class="ml-4"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Email" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select v-model="invoice.status"
                            :items="status"
                            item-text="text"
                            dense
                            outlined
                            item-value="value"
                            label="Status"
                            required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <button class="btn btn-primary"> Search</button>
                  <button class="btn btn-success"> Export To CSV</button>
                </v-col>
              </div>

            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Invoice ID</th>
                  <th class="px-3">Purchase Type</th>
                  <th class="px-3">Name</th>
                  <th class="px-3">Address</th>
                  <th class="px-3">Email</th>
                  <th class="px-3">Total Amount</th>
                  <th class="px-3">Purchase date</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr>
                    <td class="px-2">
                      <a class=""
                      >1102320</a>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >Lorem ipsum.</span>
                    </td>
                    <td>
                     <span class="text-dark-75 font-weight-bolder d-block font-size-lg"
                     >Ram Prasad Thapa</span>
                    </td>
                    <td>
                     <span class=""
                     >Kathmandu - 2</span>
                    </td>
                    <td>
                      <span class=""
                      >ram123@gmail.com</span>
                    </td>
                    <td>
                      <span class="text-dark font-weight-bolder d-block font-size-lg"
                      >1023</span>
                    </td>
                    <td>
                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                        2021, July 20
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editInvoice" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>

export default {
  data(){
    return{
      state: [
        "Australian Capital Territory",
        "New South Wales",
        "New Zealand",
        "Northern Territory",
        "Queensland",
        "South Australia",
        "South Australia & Northern Territory",
        "Tasmania",
        "Victoria",
        "Vietnam",
        "Western Australia"
      ],
      status: [
        "Pending", "Completed", "All"
      ],
      invoice: {
        state: "",
        status: null
      }
    }
  },
  methods: {
    editInvoice(){

    }
  }
}
</script>
